.allForm {
  display: flex;
  flex-direction: row;
  max-width: 900px;
  margin: 25px auto;
  min-height: 600px;
}

@media screen and (max-width: 786px) {
  .allForm {
    flex-direction: column;
  }

  .textField {
    min-height: 300px;
  }
}

.esForm {
  max-width: 500px;
  background-color: var(--elateGreen);
  display: flex;
}

.formContent {
  margin: 1rem;
  background-color: white;
  flex: 2;
  display: flex;
  flex-direction: column;
}

.formContent h1 {
  padding: 5px;
}

.textField {
  background-color: var(--elateGrey);
  min-width: 300px;
  max-width: 400px;
  padding: 10px;
  max-height: 600px;
  overflow-y: scroll;
}

.formField {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.formField h4 {
  margin: 0;
}

#formButton {
  margin: 0 auto;
  width: 100%;
  background-color: var(--elateBlue);
  color: white;
  line-height: 1.7rem;
  font-size: 1rem;
  border-radius: 10px;
}

form {
  flex: 2;
  display: flex;
  flex-direction: column;
}
