@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

.container_login {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.container_loggedIn {
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: 24px;
}

.container_row {
  display: flex;
  flex-flow: row wrap;
  margin: auto;
}

.button_style {
  margin: auto;
  margin-top: 28px;
  border: none;
  background: var(--elateGreen);
  color: #ffffff !important;
  font-weight: 100;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 6px;
  transition: all 0.3s ease 0s;
  text-shadow: 4px 4px 5px var(--elateGrey);
}

.button_style:hover {
  color: var(--elateGreen) !important;
  font-weight: 700 !important;
  letter-spacing: 2px;
  background: none;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}

.gmail_image {
  width: 38px;
  height: 38px;
  margin: auto;
}

.kibana {
  width: 94vw;
  margin: 0 auto;
  height: 93vh;
}

.auto_margin {
  margin: auto;
}

.tripletex_div {
  margin: 10px;
  padding: 10px;
  max-width: 600px;
}

.login_alert {
  margin: auto;
  margin-top: 20px;
}

.border_double {
  border-style: double;
}

.flex-1 {
  order: 1;
}

.flex-2 {
  order: 2;
}
.flex-3 {
  order: 3;
}
